import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import ReactMarkdown from 'react-markdown';
import Layout from '../js/components/Layout';
import SEO from '../components/SEO';

import { formatDate } from 'utils/dateUtils';

const MARKDOWN_IMG_REGEX = /!\[[^\]]*\]\(.*\)/g;

const replaceImages = (body, images) => {
  const bodyParts = body.split(MARKDOWN_IMG_REGEX);
  // eslint-disable-next-line no-plusplus
  const elements = [];
  for (let i = 0; i < bodyParts.length; i++) {
    elements.push((<ReactMarkdown key={`text-${i}`} children={bodyParts[i]} />));
    if (i < images.length) {
      elements.push((
        <p key={`img-${i}`}>
        <Img
          className="blog-post-image-wrapper"
          placeholderStyle={{
            'objectFit': 'contain',
          }}
          fadeIn={false}
          imgStyle={{
            'objectFit': 'contain',
          }}
          fluid={
            images[i].img.childImageSharp.fluid
          }
          alt={images[i].alt}
        />
        </p>
      ));
    }
  }
  return (
    <>
      {elements}
    </>
  );
}

const BlogPost = ({data}) => {
  const post = data.allBlogPost.edges[0].node;

  return (
    <Layout>
      <SEO title={post.title} description={post.description} fullImageUrl={post.thumbnailUrl} />
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-10 col-12 blog-post-container">
          <h1>{post.title}</h1>
          <p className="published">{`Published: ${formatDate(post.datePublished)}`}</p>
          {/* <ReactMarkdown source={post.body} /> */}
          {replaceImages(post.body, post.images)}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
query BlogPost($title: String){
  allBlogPost(filter: {title: {eq: $title}}) {
    edges {
      node {
        id
        title
        description
        thumbnailUrl
        datePublished
        body
        images {
          url
          alt
          parts
          img {
            childImageSharp {
              fluid(sizes: """
                (max-width: 300px) 200px,
                (max-width: 600px) 400px,
                (max-width: 900px) 600px,
                (max-width: 1200px) 800px,
                (max-width: 1800px) 900px,
                1200px""", 
                quality: 95, fit: COVER
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}`;

export default BlogPost;
